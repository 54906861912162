import Vue from 'vue'
import 'mutationobserver-shim'
import './Utils/filter'
import App from './App.vue'
import router from './router'
import store from './store'
import Raphael from 'raphael/raphael'
import './plugins'
// import './registerServiceWorker'
import i18n from './i18n'
import { getlocalStorage, setlocalStorage, remlocalStorage } from './Utils/localStorage'
import VueClipboard from 'vue-clipboard2'

import VueCropper from 'vue-cropper'
import http from './http/api'

import VueAMap from 'vue-amap'

import Fragment from 'vue-fragment'

import Base64 from 'js-base64'

import vueQr from 'vue-qr'

import Bus from '@/Utils/Bus'

import './assets/js/set_root'

// 挂载Swiper   == > 暂未使用
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import { BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
// Google Maps
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(Fragment.Plugin)
Vue.use(Base64)
Vue.component('vueQr', vueQr)
Vue.prototype.$bus = Bus
Vue.use(VueClipboard)
Vue.use(VueCropper)
Vue.use(VueAMap)
Vue.use(VueGoogleMaps, {
  load: {
    // Add your API key here
    key: 'AIzaSyDYAjJ-y5cRPvO92XMWrJGmyFZ-hwxjR2w',
    libraries: 'places', // This is required if you use the Auto complete plug-in
    language: 'en'
  }
})
Vue.use(VueAwesomeSwiper)
Vue.prototype.$getlocalStorage = getlocalStorage
Vue.prototype.$setlocalStorage = setlocalStorage
Vue.prototype.$remlocalStorage = remlocalStorage
Vue.use(http)
Vue.use(BootstrapVueIcons)
// 防止连续点击按钮
Vue.directive("preventRe", {
  bind(el, binding) {
    let timer;
    el.addEventListener("click", () => {
      if (!el.disabled) {
        el.disabled = true
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(() => {
          el.disabled = false
        }, binding.value || 3000)
      }
    });
  },
});
Vue.directive('removeAriaHidden', {
  bind(el, binding) {
    const ariaEls = el.querySelectorAll('.el-radio__original')
    ariaEls.forEach((item) => {
      item.removeAttribute('aria-hidden')
    })
  }
})
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations()
    .then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister()
      }
    })
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then(registration => {
    registration.unregister()
  })
}

Date.prototype.Format = function (fmt) { // author: meizz
  var o = {
    'M+': this.getMonth() + 1, // 月份
    'd+': this.getDate(), // 日
    'h+': this.getHours(), // 小时
    'm+': this.getMinutes(), // 分
    's+': this.getSeconds(), // 秒
    'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
    'S': this.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) { fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length)) }
  for (var k in o) { if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))) }
  return fmt
}

global.Raphael = Raphael
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
})
Vue.config.productionTip = false

// 表头文字重新渲染共用方法
Vue.prototype.$renderHeader = (h, { column, $index }) => {
  // 新建一个 span
  let span = document.createElement('span')
  // 设置表头名称
  span.innerText = column.label
  // 临时插入 document
  document.body.appendChild(span)
  // 重点：获取 span 最小宽度，设置当前列，注意这里加了 20，字段较多时还是有挤压，且渲染后的 div 内左右 padding 都是 10，所以 +20 。（可能还有边距/边框等值，需要根据实际情况加上）
  let minWidth = span.getBoundingClientRect().width + 20
  column.minWidth = minWidth > 150 ? minWidth : 150
  // 移除 document 中临时的 span
  document.body.removeChild(span)
  return h('span', column.label)
}

// 自适应表格列宽
Vue.prototype.$flexColumnWidth = (str, arr1, label) => {
  // str为该列的字段名(传字符串);tableData为该表格的数据源(传变量);
  str = str + ''
  let columnContent = ''
  // if (!arr1 || !arr1.length || arr1.length === 0 || arr1 === undefined) {
  //   return
  // }
  // if (!str || !str.length || str.length === 0 || str === undefined) {
  //   return
  // }

  // 获取该列中最长的数据(内容)
  let index = 0
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i][str] === null) {
      return
    }
    const now_temp = arr1[i][str] + ''
    const max_temp = arr1[index][str] + ''
    if (now_temp.length > max_temp.length) {
      index = i
    }
    columnContent = arr1[index][str]
  }
  // console.log('该列数据[i]:', columnContent)
  // 以下分配的单位长度可根据实际需求进行调整
  let flexWidth = 0
  for (const char of columnContent) {
    if (char >= 'a' && char <= 'z') {
      // 如果是英文小写字符，为字符分配8个单位宽度
      flexWidth += 8
    } else if (char >= 'A' && char <= 'Z') {
      // 如果是英文大写字符，为字符分配12个单位宽度
      flexWidth += 12
    } else if (char >= '\u4e00' && char <= '\u9fa5') {
      // 如果是中文字符，为字符分配15个单位宽度
      flexWidth += 15
    } else {
      // 其他种类字符，为字符分配8个单位宽度
      flexWidth += 8
    }
  }
  let labelWidth = 0
  if (label) {
    // console.log(label);
    for (const char of label) {
      if (char >= 'a' && char <= 'z') {
        labelWidth += 12
      } else if (char >= 'A' && char <= 'Z') {
        labelWidth += 12
      } else if (char >= '\u4e00' && char <= '\u9fa5') {
        labelWidth += 15
      } else {
        labelWidth += 15
      }
    }
  }
  if (flexWidth < 80) {
    // 设置最小宽度
    flexWidth = 80
  }
  if (flexWidth < labelWidth) {
    flexWidth = labelWidth
  }
  // if (flexWidth > 250) {
  //   // 设置最大宽度
  //   flexWidth = 250
  // }
  // console.log(labelWidth);
  // console.log(flexWidth);
  return flexWidth + 20 + 'px'
}

let vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

window.vm = vm
window.payStatus = ''
/**
 *
 *
 * */
