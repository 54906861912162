import axios from 'axios'
import router from '@/router'
import Vue from 'vue'
import { Message, Loading } from 'element-ui'
import { chatApiList, removeLoadingList } from './differentApi'
import { getlocalStorage } from '@/Utils/localStorage'
import { funExemptTokenApi, funEncryption, funDecryptEncryption } from './requestConfig'
import { BASE_URL, chatRequestUrl, version } from '@/global'
import { message } from '../Utils/OnlyMessage'
// console.log("BASE_URL", BASE_URL)
let baseURL
let loading // 定义loading变量
function startLoading() {
  loading = Loading.service({
    lock: true // 锁屏滚动
    // spinner: 'el-icon-loading', // 自定义图标
    // text: 'Desperately loading',
    // text: 'More JC Spirits'

    // background: 'rgba(0, 0, 0, 0.7)'
  })
}
function endLoading() {
  // loading.close()
}

let sameRequestQuantity = 0 //  同一请求数量
// baseURL = 'https://chat.summer.kim
export default function $axios(options) {
  chatApiList.includes(options.url) ? baseURL = chatRequestUrl : baseURL = BASE_URL
  return new Promise(async (resolve, reject) => {
    const service = axios.create({
      baseURL,
      timeout: 30000
    })

    let token = ''
    let lang
    let private_key
    // 传入请求参数进行加密
    // 并拿到客户端私钥用于下面响应解密
    if (options.encryptionType) {
      let obj = await funEncryption(options)
      private_key = obj.private_key
    }
    // request 拦截器
    service.interceptors.request.use(
      config => {
        if (!removeLoadingList.includes(config.url)) {
          sameRequestQuantity++
          if (config.url != '/api/sendModelChat' && config.url != '/api/getModelChat') {
            // startLoading()
          }
        }
        // token
        token = getlocalStorage('token')
        lang = getlocalStorage('langType') || 'en'
        if (token) {
          config.headers.authorization = token
          config.headers.lang = lang
          if (!chatApiList.includes(options.url)) {
            config.headers.version = version
          }
        } else {
          if (window.vm.$route.path == '/auth/password-reset') {
            config.headers.Authorization = window.vm.$route.query.token
          } else {
            // 判断是否是免token接口
            funExemptTokenApi(config.url)
          }
        }
        return config
      },

      error => {
        // 请求错误时
        // 1. 判断请求超时
        if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
          console.log('timeout请求超时')
          return service.request(options) // 再重复请求一次
        }
        return Promise.reject(error) // 在调用的那边可以拿到(catch)你想返回的错误信息
      }
    )

    // response 拦截器
    service.interceptors.response.use(
      res => {
        let data
        // IE9时response.data是undefined，因此需要使用response.request.responseText(Stringify后的字符串)
        if (res.data == undefined) {
          data = JSON.parse(res.request.responseText)
        } else {
          data = res.data
        }
        // 响应解密
        // if (options.encryptionType && data.status == 200 ) {
        //   funDecryptEncryption(options, private_key, data)
        //   endLoading()
        // }
        let messageCode = [200, 10088, 10087, 10089]
        if (options.encryptionType && (messageCode.includes(data.status))) {
          funDecryptEncryption(options, private_key, data)
          endLoading()
        }

        if (!removeLoadingList.includes(res.config.url)) {
          sameRequestQuantity--
          if (sameRequestQuantity <= 0) {
            sameRequestQuantity = 0
            if (res.config.url != '/api/sendModelChat') {
              endLoading()
            }
          }
          // console.log('卸载了', sameRequestQuantity);
        }
        let notMessage = [
          'success.get_success',
          'get success',
          'success',
          'Successful get', //  列表消息获取 => 返回
          'Like success',
          'Bind successfully', // 聊天室绑定接口
          'Like deleted successfully', // 项目评论点赞取消接口
          'Cleared successfully', // 聊天室点击好友清除消息角标接口
          'Sent successfully', // 聊天室发送消息接口
          'Upload successfully', // 上传图片
          'Comment successful',
          'Added successfully', // 评论成功
          'Delete successfully',
          'Edit successfully',
          'Disbanded successfully',
          'Operation succeeded',
          'Payment successful',
          'Complaint successful',
          'Edit information successfully',
          'Successful transfer',
          'Reply successfully',
          'Comment deleted successfully',
          'Reply deleted successfully',
          'Like deleted successfully',
          'Cancel the like successfully',
          'Followed',
          'Unfollow successfully',
          'Add itinerary successfully',
          'Itinerary canceled successfully',
          'Itinerary payment is successful',
          ' Join the group'// 视频聊天
        ]
        // 退出登录
        if (res.data.status == '952798') {
          localStorage.removeItem('token')
          router.push({
            name: 'auth1.sign-in1'
          })
        }
        // 拦截地址列表弹框
        if (res.data.status == 6027) {
          // Message.warning(res.data.message);
          return false
        }

        // 需要success提示
        if (res.data.status == 200 && !notMessage.includes(res.data.message) && res.data.is_popup) {
          Message.success(res.data.message)
        }

        // 需要警告提示
        if (res.data.status == 9999) {
          Message.warning(res.data.message)
        }

        // 需要错误弹窗
        let notErrorMessage = [
          9988,
          9999,
          4101, // 首页点赞取消接口
          10088,
          10087,
          10089,
          999,
          4001, //捐赠订阅支付-----未支付
          4002, //捐赠订阅支付-----订阅已取消
        ]
        if (res.data.status != 200 && !notErrorMessage.includes(res.data.status)) {
          // 需要警告提示
          if (res.data.status == 952798) {
            localStorage.clear()
            router.push('/auth/sign-in1')
          }
          message.warning(res.data.message)
        }

        return res.data
      },
      err => {
        sameRequestQuantity--
        endLoading()
        return Promise.reject(err)
      }
    ) /
      // console.log('最后的次数', sameRequestQuantity);
      // 请求处理
      service(options).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
  })
}
