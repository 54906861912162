import { SkeletonItem } from 'element-ui'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { getlocalStorage } from '../Utils/localStorage'

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

// 通知页面
const FriendRequest = () =>
  import('../views/NotificationsAll/FriendRequest.vue')
const Notification = () => import('../views/NotificationsAll/Notification.vue')

/* Layouts */
const Layout = () => import('../layouts/Layout')
const AuthLayout = () => import('../layouts/AuthLayouts/AuthLayout')

// 搜索页面
const SearchResult = () => import('../views/SearchResult/Search')

/**
 * AuthView
 * */
const SignIn1 = () => import('../views/AuthPages/SignIn1')
const DeleteAccount = () => import('../views/AuthPages/DeleteAccount')
const SignUp1 = () => import('../views/AuthPages/SignUp1')
const Authentication = () => import('../views/AuthPages/Authentication') // 认证方式
const RecoverPasswordBefore = () =>
  import('../views/AuthPages/RecoverPasswordBefore.vue') // 找回密码前
const RecoverPassword = () => import('../views/AuthPages/RecoverPassword') // 找回密码
const reset2Fa = () => import('../views/AuthPages/reset2Fa') // 找回密码
const resetPayword = () => import('../views/AuthPages/resetPayword') // 设置支付密码
const Recovery = () => import('../views/AuthPages/Recovery') //
const Locked = () => import('../views/AuthPages/Locked') //
const Error = () => import('../views/AuthPages/Error404') //
const AccountRecoveryBefore = () =>
  import('../views/AuthPages/AccountRecoveryBefore.vue') //

const AccountRecoveryAfter = () =>
  import('../views/AuthPages/AccountRecoveryAfter.vue')

/**
 * Newsfeed
 * */
const Newsfeed = () => import('../views/Newsfeed/Newsfeed')

// 发票
const Invoice = () => import('../views/Public/Invoice.vue')

/**
 *  Profile
 * */
const Profile = () => import('../views/Profile/Profile')
const Wallet = () => import('../views/Wallet/Wallet')
const Settings = () => import('../views/Settings/Settings')
const RecoverPayWord = () =>
  import('../views/Settings/Components/RecoverPayWord')
const PayMoney = () => import('../views/Public/PayMoney.vue')
const PayMember = () => import('../views/Public/PayMember.vue')
const PaySuccess = () => import('../views/Public/PaySuccess.vue')
const RecSuccess = () => import('../views/Public/RecSuccess.vue')
const PayFailed = () => import('../views/Public/PayFailed.vue')

/**
 *  Channel
 * */
const ChannelList = () =>
  import('../views/Channel/ChannelList/ChannelList.vue')
const ChannelDetail = () =>
  import('../views/Channel/ChannelDetail/ChannelDetail')

/**
 *  Group
 * */
const Group = () => import('../views/Group/Group')
const GroupDetail = () => import('../views/Group/GroupDetail/GroupDetail')
/**
 * Club100
 * **/ 
const Club = () => import('../views/Club100/Club')
const ClubDet = () => import('../views/Club100/ClubDet')
const ContactClub = () => import('../views/Club100/ContactClub')
const BecomeMember = () => import('../views/Club100/BecomeMem')
/**
 *  Chat
 * */
const Chat = () => import('../views/Chat/Chat.vue')

/**
 *  Donation
 * */

const Donation = () => import('../views/Donation/Donation')
const JCI = () => import('../views/Donation/JCI')
const JCIForm = () => import('../views/Donation/Form/JCIForm')
const Foundation = () => import('../views/Donation/Fundation/Fundation')
const FoundationForm = () => import('../views/Donation/Form/FundationForm')
const Other = () => import('../views/Donation/Other')
const OtherForm = () => import('../views/Donation/Form/OtherForm')

const MyDonation = () => import('../views/Donation/MyDonation/MyDonationCopy')

/*****
 * 新流程公用部分
 * 项目
 * 课程
 * 活动
 * 会议
 */

/**
 * public公用部分
 * */
const Program = () =>
  import('../components/PublicProcess/PublicTop/childrens/Program')
const PlanToBuy = () =>
  import('../components/PublicProcess/PublicTop/childrens/PlanToBuy')
const MyProgram = () =>
  import('../components/PublicProcess/PublicTop/childrens/MyProgram')
const MySchedule = () =>
  import('../components/PublicProcess/PublicTop/childrens/MySchedule')
const MyEventSchedule = () =>
  import('../components/PublicProcess/EventTop/childrens/MySchedule')
const AllProgram = () =>
  import('../components/PublicProcess/PublicTop/childrens/AllProgram')
const EventProgram = () =>
  import('../components/PublicProcess/EventTop/childrens/AllProgram')

/**
 *  Project
 * */
const Project = () => import('../views/Project/Project')
const ProjectDetail = () => import('../views/Project/ProjectDetail')

/**
 *  JCI Projects
 * */
const ProjectGallery = () => import('../views/ProjectGallery/Project')
const MyProject = () => import('../views/ProjectGallery/MyProject')
const ProjectsDetail = () => import('../views/ProjectGallery/ProjectDetail')

/**
 * 活动
 * **/

const Event = () => import('../views/Event/Event')
const EventDetail = () => import('../views/Event/EventDetail')
// const MyEvent = () => import('../views/Event/MyEvent.vue')
/**
 * 课程
 * **/
const Course = () => import('../views/Course/Course')
const CourseDetail = () => import('../views/Course/CourseDetail')
const MyCourse = () => import('../views/Course/MyCourse.vue')
const CertificateTest = () => import('../views/Course/components/SkillTest.vue')
const TestDetail = () => import('../views/Course/components/TestDetail.vue')
const TestResult = () => import('../views/Course/components/TestResult.vue')
const MyCertificate = () => import('../views/Course/MyCertificate.vue')

/**
 * 会议
 * **/

const Meeting = () => import('../views/Meeting/Meeting')
const MeetingDetail = () => import('../views/Meeting/MeetingDetail')

/**
 *  Award
 * */

const Award = () => import('../views/Award/Award')
const SecondLevel = () => import('../views/Award/ProgramSecLevel.vue')
const AwardDetail = () => import('../views/Award/AwardDetail.vue')
const AwardCertificate = () => import('../views/Award/MyCertificate.vue')
const WinnerDetail = () => import('../views/Award/Components/AwardDetail/WinnerDetail.vue')

/**
 *  Skill
 * */

const Skill = () => import('../views/Skill/Skill.vue')
const SkillSecondLevel = () => import('../views/Skill/SkillSecLevel.vue')
const TrainingDetail = () => import('../views/Skill/TrainingDetail.vue')
const SkillTest = () => import('../views/Skill/SkillTest.vue')
const MyTraining = () => import('../views/Skill/MyTraining.vue')

/**
 *  Webstore
 * */

const Webstore = () => import('../views/Webstore/Webstore.vue')
/**  webstore模块的components **/
const WebstoreAllGoods = () =>
  import('../views/Webstore/Components/WebstoreAllGoods.vue')
const WebstoreAllShop = () =>
  import('../views/Webstore/Components/WebstoreAllShop.vue')
const WebstoreCart = () =>
  import('../views/Webstore/Components/WebstoreCart.vue')
const WebstoreGoodsDetails = () =>
  import('../views/Webstore/Components/WebstoreGoodsDetails.vue')
// const WebstoreMyorder = () =>
//   import('../views/Webstore/Components/WebstoreMyorder.vue')
const WebstoreShippingAddress = () =>
  import('../views/Webstore/Components/WebstoreShippingAddress.vue')
const WebstoreShop = () =>
  import('../views/Webstore/Components/WebstoreShop.vue')

/**   webstore模块的Shop **/
const OrderMail = () => import('../views/Webstore/Shop/OrderMail.vue')
const OrderOwn = () => import('../views/Webstore/Shop/OrderOwn.vue')
const OrderOwnself = () => import('../views/Webstore/Shop/OrderOwnself.vue')
const PlaceOrder = () => import('../views/Webstore/Shop/PlaceOrder.vue')
const MyOrder = () => import('../views/Webstore/Shop/MyOrder.vue')
const OrderToPay = () => import('../views/Webstore/Shop/OrderToPay.vue')
const OrderToDelivered = () =>
  import('../views/Webstore/Shop/OrderToDelivered.vue')
const OrderToReceived = () =>
  import('../views/Webstore/Shop/OrderToReceived.vue')
const OrderToEvaluated = () =>
  import('../views/Webstore/Shop/OrderToEvaluated.vue')
const OrderRefund = () => import('../views/Webstore/Shop/OrderRefund.vue')
const OrderRefundDetails1 = () =>
  import('../views/Webstore/Shop/OrderRefundDetails1.vue')
const OrderRefundDetails2 = () =>
  import('../views/Webstore/Shop/OrderRefundDetails2.vue')
const OrderRefundDetails3 = () =>
  import('../views/Webstore/Shop/OrderRefundDetails3.vue')
const StoreAddress1 = () => import('../views/Webstore/Shop/StoreAddress1.vue')

/**  webstore模块的score */
const PointsMerchandise = () =>
  import('../views/Webstore/Score/PointsMerchandise.vue')
const ShopScoreDetails = () =>
  import('../views/Webstore/Score/ShopScoreDetails.vue')
const ScorePlaceOrder = () =>
  import('../views/Webstore/Score/ScorePlaceOrder.vue')
const MyExchange = () => import('../views/Webstore/Score/MyExchange.vue')
const ExchangeResult = () => import('../views/Webstore/Score/ExchangeResult.vue')
const ScoreOrderDetails = () =>
  import('../views/Webstore/Score/ScoreOrderDetails.vue')

/* 其他订单管理 */
const MyOtherOrder = () => import('../views/MyOtherOrder/MyOtherOrder.vue')
const eventOrderDet = () => import('../views/MyOtherOrder/orderDet/orderDet.vue')
const mobileUserList = () => import('../views/MyOtherOrder/components/MobileList/UserList.vue')
const mobileHistoryList = () => import('../views/MyOtherOrder/components/MobileList/HistoryList.vue')
/**
 *  Senator
 * */

const Senator = () => import('../views/Senator/Senator.vue')

// 用户须知
const PrivacyAndPolicy = () => import('../views/PrivacyAndPolicy/PrivacyAndPolicy.vue')

const Video = () => import('../views/ProgramVideo/Video')
const VideoChat = () => import('../views/ProgramVideo/VideoChat')

// library
const Library = () => import('../views/Library/LibraryList')

// Authorize
const Authorize = () => import('../views/Authorize/Authorize.vue')

// judgeScore
const JudgeScore = () => import('../views/AdminAgent/AdminAgent.vue')

/**
 * ComComponent
 */

/**
 * Children-Router-Function
 * */

/* AuthPage */
const authChildRoutes = (prop, mode = false) => [
  {
    path: 'sign-in1',
    name: prop + '.sign-in1',
    component: SignIn1
  },
  {
    path: 'delete-account',
    name: prop + '.delete-account',
    component: DeleteAccount
  },
  {
    path: 'RecoverPayWord',
    name: prop + '.RecoverPayWord',
    component: RecoverPayWord
  },
  {
    path: 'sign-up1',
    name: prop + '.sign-up1',
    component: SignUp1
  },
  {
    path: 'authentication',
    name: prop + '.authentication',
    meta: { auth: true },
    component: Authentication
  },
  {
    path: 'password-reset-before',
    name: prop + '.recoverPasswordBefore',
    meta: { auth: true },
    component: RecoverPasswordBefore
  },
  {
    path: 'password-reset',
    name: prop + '.password-reset',
    meta: { auth: true },
    component: RecoverPassword
  },
  {
    path: '2fa-reset',
    name: prop + '.2fa-reset',
    meta: { auth: true },
    component: reset2Fa
  },
  {
    path: 'reset-payword',
    name: prop + '.reset-payword',
    meta: { auth: true },
    component: resetPayword
  },
  {
    path: 'recovery',
    name: prop + '.recovery',
    meta: { auth: true },
    component: Recovery
  },
  {
    path: 'locked',
    name: prop + '.locked',
    meta: { auth: false },
    component: Locked
  },
  {
    path: 'account-recovery-before',
    name: prop + '.accountRecoveryBefore',
    meta: { auth: true },
    component: AccountRecoveryBefore
  },
  {
    path: 'account-recovery-after',
    name: prop + '.accountRecoveryAfter',
    meta: { auth: true },
    component: AccountRecoveryAfter
  }
]

/**
 * Newfeeds
 * Profile
 * wallet
 * settings
 * course
 * meeting
 * event
 * chat
 * donation
 * project
 * award
 * webstore
 * senator
 * */
const pageRoute = (prop, mode = false) => [
  // 通知页面

  {
    path: 'friend-request',
    name: prop + '.friend-request',
    component: FriendRequest
  },
  {
    path: 'notification',
    name: prop + '.notification',
    component: Notification
  },
  // 搜索页面
  {
    path: 'search-result',
    name: prop + '.search-result',
    component: SearchResult
  },
  // newsfeed
  {
    path: '/',
    redirect: '/newsfeeds'
  },
  {
    path: '/newsfeeds/:id?',
    name: prop + '.home',
    component: Newsfeed
  },

  // profile
  {
    path: '/profile',
    name: prop + '.profile',
    component: Profile
  },

  {
    path: '/invoice',
    name: prop + '.invoice',
    component: Invoice
  },

  {
    path: '/wallet',
    name: prop + '.wallet',
    component: Wallet,
    meta: {
      parent: prop + '.profile'
    }
  },
  {
    path: '/settings',
    name: prop + '.settings',
    component: Settings,
    meta: {
      parent: prop + '.profile'
    }
  },
  // 支付
  {
    path: '/PayMoney',
    props: true,
    name:  'PayMoney',
    component: PayMoney
  },
  {
    path: '/PayMember',
    props: true,
    name: prop + '.settings-pay',
    component: PayMember
  },
  {
    path: '/PaySuccess',
    props: true,
    name: prop + '.settings-pay',
    component: PaySuccess
  },
  {
    path: '/RecSuccess',
    props: true,
    name: prop + '.settings-pay',
    component: RecSuccess
  },
  {
    path: '/PayFailed',
    props: true,
    name: prop + '.settings-pay',
    component: PayFailed
  },

  // course

  {
    path: '/course',
    name: prop + '.course',
    meta: {
      auth: true,
      name: 'Course',
      img: require('../assets/images/pageBgImg/courseBg.png')
    },
    component: Course
  },
  {
    path: '/course-detail',
    name: prop + '.course-detail',
    component: CourseDetail,
    meta: {
      parent: prop + '.course'
    }
  },
  {
    path: '/my-course',
    name: prop + '.my-course',
    component: MyCourse,
    meta: {
      auth: true,
      name: 'My-Course',
      img: require('../assets/images/pageBgImg/courseBg.png'),
      parent: prop + '.course'
    }
  },
  {
    path: '/certificate-test',
    name: prop + '.certificate-test',
    component: CertificateTest,
    meta: {
      parent: prop + '.course'
    }
  },
  {
    path: '/test-detail',
    name: prop + '.test-detail',
    component: TestDetail,
    meta: {
      parent: prop + '.course'
    }
  },
  {
    path: '/my-certificate',
    name: prop + '.my-certificate',
    component: MyCertificate,
    meta: {
      auth: true,
      name: 'My-Certificate',
      img: require('../assets/images/pageBgImg/courseBg.png'),
      parent: prop + '.course'
    }
  },

  {
    path: '/TestResult',
    name: prop + '.test-result',
    component: TestResult,
    meta: {
      auth: true,
      name: 'test-result',
      img: require('../assets/images/pageBgImg/courseBg.png'),
      parent: prop + '.course'
    }
  },

  /** meeting */
  {
    path: '/Meeting',
    name: prop + '.meeting',
    meta: {
      auth: true,
      name: 'Meeting'
      // img: require('../assets/images/pageBgImg/meetingEventBg.png')
      // img: require('../assets/images/public/big_banner.jpg')
    },
    component: Meeting
  },
  {
    path: '/meeting-details',
    props: true,
    name: prop + '.meeting-details',
    component: MeetingDetail,
    meta: {
      parent: prop + '.meeting'
    }
  },

  /* event */
  {
    path: '/event',
    name: prop + '.event',
    meta: {
      auth: true,
      name: 'Events',
      img: require('../assets/images/event/event_banner.png')
    },
    component: Event
  },
  {
    path: '/event-detail',
    name: prop + '.event-detail',
    component: EventDetail,
    meta: {
      parent: prop + '.event'
    }
  },

  /* Chat */
  {
    path: '/chat',
    name: prop + '.chat',
    component: Chat
  },

  /* channel */
  {
    // path: '/channel-list',
    // name: prop + '.channel-list',
    path: '/jci-organizations',
    name: prop + '.jci-organizations',
    meta: {
      auth: true,
      name: 'Channel'
      // img: require('../assets/images/pageBgImg/channelBg.png')
      // img: require('../assets/images/public/big_banner.jpg')
    },
    component: ChannelList
  },
  {
    // path: '/channel-detail',
    // name: prop + '.channel-detail',
    path: '/organization-detail',
    name: prop + '.organization-detail',
    component: ChannelDetail,
    meta: {
      parent: prop + '.jci-organizations'
    }
  },

  /* Group */
  {
    path: '/group',
    name: prop + '.group',
    meta: {
      auth: true,
      name: 'Interest Club'
      // img: require('../assets/images/pageBgImg/groupBg.png')
      // img: require('../assets/images/public/big_banner.jpg')
    },
    component: Group
  },

  {
    path: '/group-detail',
    name: prop + '.group-detail',
    component: GroupDetail,
    meta: {
      parent: prop + '.group'
    }
  },
  /* Club */ 
  {
    path: '/club',
    name: prop + '.club',
    meta: {
      auth: true,
      name: 'Club100'
    },
    component: Club
  },
  {
    path: '/clubDet',
    name: prop + '.clubDet',
    meta: {
      auth: true,
      parent: prop + '.club',
      name: 'ClubDet'
    },
    component: ClubDet
  },
  {
    path: '/contactClub',
    name: prop + '.contactClub',
    meta: {
      auth: true,
      parent: prop + '.club',
      name: 'ContactClub'
    },
    component: ContactClub
  },
  {
    path: '/becomeMember',
    name: prop + '.becomeMember',
    meta: {
      auth: true,
      parent: prop + '.club',
      name: 'BecomeMember'
    },
    component: BecomeMember
  },
  /* donation */
  {
    path: '/donation',
    name: prop + '.donation',
    meta: {
      auth: true,
      name: 'Donation'
      // img: require('../assets/images/page-img/profile-bg7.jpg')
      // img: require('../assets/images/public/big_banner.jpg')
    },
    component: Donation
  },
  {
    path: '/jci',
    name: prop + '.jci',
    component: JCI,
    meta: {
      parent: prop + '.donation'
    }
  },
  {
    path: '/jci-form',
    name: prop + '.jci-form',
    component: JCIForm,
    meta: {
      parent: prop + '.donation'
    }
  },
  {
    path: '/foundation',
    name: prop + '.foundation',
    component: Foundation,
    meta: {
      parent: prop + '.donation'
    }
  },
  {
    path: '/foundation-form',
    name: prop + '.foundation-form',
    component: FoundationForm,
    meta: {
      parent: prop + '.donation'
    }
  },
  {
    path: '/other',
    name: prop + '.other',
    component: Other,
    meta: {
      parent: prop + '.donation'
    }
  },
  {
    path: '/other-form',
    name: prop + '.other-form',
    component: OtherForm,
    meta: {
      parent: prop + '.donation'
    }
  },

  {
    path: '/my-donation',
    name: prop + '.my-donation',
    component: MyDonation,
    meta: {
      parent: prop + '.donation'
    }
  },

  /* project */
  {
    path: '/project',
    name: prop + '.project',
    meta: {
      auth: true,
      name: 'Project',
      img: require('../assets/images/pageBgImg/projectBg.png')
    },
    component: Project
  },
  {
    path: '/project-detail',
    props: true,
    name: prop + '.project-detail',
    component: ProjectDetail,
    meta: {
      parent: prop + '.project'
    }
  },

  /* JCI Projects */
  {
    path: '/project-gallery',
    name: prop + '.projects',
    meta: {
      auth: true,
      name: 'JCI Projects'
      // img: require('../assets/images/pageBgImg/projectBg.png')
    },
    component: ProjectGallery
  },
  {
    path: '/my-project',
    name: prop + '.my-projects',
    meta: {
      auth: true,
      name: 'My Projects'
      // img: require('../assets/images/pageBgImg/projectBg.png')
    },
    component: MyProject
  },
  {
    path: '/projects-detail',
    props: true,
    name: prop + '.projects-detail',
    component: ProjectsDetail,
    meta: {
      parent: prop + '.project'
    }
  },

  /**
   * 公共部分
   *
   * */
  {
    path: '/Program',
    name: prop + '.Program',
    component: Program
  },
  {
    path: '/PlanToBuy',
    name: prop + '.plan-to-buy',
    component: PlanToBuy
  },
  {
    path: '/MyProgram',
    name: prop + '.MyProgram',
    component: MyProgram
  },
  {
    path: '/MySchedule',
    name: prop + '.MySchedule',
    component: MySchedule
  },
  {
    path: '/myEventSchedule',
    name: prop + '.myEventSchedule',
    component: MyEventSchedule
  },
  {
    path: '/AllProgram',
    name: prop + '.AllProgram',
    component: AllProgram
  },
  {
    path: '/EventProgram',
    name: prop + '.EventProgram',
    component: EventProgram
  },
  {
    path: '/MeetingProgram',
    name: prop + '.MeetingProgram',
    component: EventProgram
  },

  /* Award */
  {
    path: '/award',
    name: prop + '.award',
    meta: {
      auth: true,
      name: 'Award'
      // img: require('../assets/images/pageBgImg/award_banner.png')
      // img: require('../assets/images/public/big_banner.jpg')
    },
    component: Award
  },

  {
    path: '/second-level',
    props: true,
    name: prop + '.second-level',
    component: SecondLevel,
    meta: {
      parent: prop + '.award'
    }
  },
  {
    path: '/award-detail',
    props: true,
    name: prop + '.award-detail',
    component: AwardDetail,
    meta: {
      parent: prop + '.award'
    }
  },
  {
    path: '/winner-detail',
    props: true,
    name: prop + '.winner-detail',
    component: WinnerDetail,
    meta: {
      parent: prop + '.winner'
    }
  },
  {
    path: '/award-certificate',
    props: true,
    name: prop + '.award-certificate',
    component: AwardCertificate,
    meta: {
      auth: true,
      name: 'Program-Certificate',
      img: require('../assets/images/pageBgImg/courseBg.png'),
      parent: prop + '.award'
    }
  },

  /* Skill */
  {
    path: '/skill',
    name: prop + '.skill',
    meta: {
      auth: true,
      name: 'Skills'
      // img: require('../assets/images/skill/skill_banner.png')
      // img: require('../assets/images/public/big_banner.png')
    },
    component: Skill
  },
  {
    path: '/skill_course',
    props: true,
    name: prop + '.skill_course',
    component: SkillSecondLevel,
    meta: {
      parent: prop + '.skill'
    }
  },
  {
    path: '/training_detail',
    props: true,
    name: prop + '.training_detail',
    component: TrainingDetail,
    meta: {
      parent: prop + '.skill'
    }
  },
  {
    path: '/skill_test',
    props: true,
    name: prop + '.skill_test',
    component: SkillTest,
    meta: {
      parent: prop + '.skill'
    }
  },
  {
    path: '/my_training',
    props: true,
    name: prop + '.my_training',
    component: MyTraining,
    meta: {
      parent: prop + '.skill'
    }
  },

  /* Library */
  {
    path: '/library',
    props: true,
    name: prop + '.library',
    component: Library,
    meta: {
      parent: prop + '.library'
      // img: require('../assets/images/library/bannerTop.png')
      // img: require('../assets/images/public/big_banner.jpg')
    }
  },

  /* webstore */
  {
    path: '/webstore',
    name: prop + '.webstore',
    component: Webstore
  },
  {
    path: '/webstoreallgoods',
    name: prop + '.webstoreallgoods',
    component: WebstoreAllGoods,
    meta: {
      parent: prop + '.webstore'
    }
  },
  {
    path: '/webstoreallshop',
    name: prop + '.webstoreallshop',
    component: WebstoreAllShop,
    meta: {
      parent: prop + '.webstore'
    }
  },
  {
    path: '/webstorecart',
    name: prop + '.webstorecart',
    component: WebstoreCart,
    meta: {
      parent: prop + '.webstore'
    }
  },
  {
    path: '/webstoregoodsdetails',
    name: prop + '.webstoregoodsdetails',
    component: WebstoreGoodsDetails,
    meta: {
      parent: prop + '.webstore'
    }
  },
  // {
  //   path: '/webstoremyorder',
  //   name: prop + '.webstoremyorder',
  //   component: WebstoreMyorder,
  //   meta: {
  //     parent: prop + '.webstore'
  //   }
  // },
  {
    path: '/webstoreshippingaddress',
    name: prop + '.webstoreshippingaddress',
    component: WebstoreShippingAddress,
    meta: {
      parent: prop + '.webstore'
    }
  },
  {
    path: '/webstoreshop',
    name: prop + '.webstoreshop',
    component: WebstoreShop,
    meta: {
      parent: prop + '.webstore'
    }
  },
  {
    path: '/ordermail',
    name: prop + '.ordermail',
    component: OrderMail,
    meta: {
      parent: prop + '.webstore'
    }
  },
  {
    path: '/orderown',
    name: prop + '.orderown',
    component: OrderOwn,
    meta: {
      parent: prop + '.webstore'
    }
  },
  {
    path: '/orderownself',
    name: prop + '.orderownself',
    component: OrderOwnself,
    meta: {
      parent: prop + '.webstore'
    }
  },
  {
    path: '/placeorder',
    name: prop + '.placeorder',
    component: PlaceOrder,
    meta: {
      parent: prop + '.webstore'
    }
  },
  {
    path: '/myorder',
    name: prop + '.myorder',
    component: MyOrder,
    meta: {
      parent: prop + '.webstore'
    }
  },
  {
    path: '/ordertopay',
    name: prop + '.ordertopay',
    component: OrderToPay,
    meta: {
      parent: prop + '.webstore'
    }
  },
  {
    path: '/ordertodelivered',
    name: prop + '.ordertodelivered',
    component: OrderToDelivered,
    meta: {
      parent: prop + '.webstore'
    }
  },
  {
    path: '/ordertoreceived',
    name: prop + '.ordertoreceived',
    component: OrderToReceived,
    meta: {
      parent: prop + '.webstore'
    }
  },
  {
    path: '/ordertoevaluated',
    name: prop + '.ordertoevaluated',
    component: OrderToEvaluated,
    meta: {
      parent: prop + '.webstore'
    }
  },
  {
    path: '/orderrefund',
    name: prop + '.orderrefund',
    component: OrderRefund,
    meta: {
      parent: prop + '.webstore'
    }
  },
  {
    path: '/orderrefunddetails1',
    name: prop + '.orderrefunddetails1',
    component: OrderRefundDetails1,
    meta: {
      parent: prop + '.webstore'
    }
  },
  {
    path: '/orderrefunddetails2',
    name: prop + '.orderrefunddetails2',
    component: OrderRefundDetails2,
    meta: {
      parent: prop + '.webstore'
    }
  },
  {
    path: '/orderrefunddetails3',
    name: prop + '.orderrefunddetails3',
    component: OrderRefundDetails3,
    meta: {
      parent: prop + '.webstore'
    }
  },
  {
    path: '/storeaddress1',
    name: prop + '.storeaddress1',
    component: StoreAddress1,
    meta: {
      parent: prop + '.webstore'
    }
  },
  {
    path: '/pointsmerchandise',
    name: prop + '.pointsmerchandise',
    component: PointsMerchandise,
    meta: {
      parent: prop + '.webstore'
    }
  },
  {
    path: '/shopscoredetails',
    name: prop + '.shopscoredetails',
    component: ShopScoreDetails,
    meta: {
      parent: prop + '.webstore'
    }
  },
  {
    path: '/scoreplaceorder',
    name: prop + '.scoreplaceorder',
    component: ScorePlaceOrder,
    meta: {
      parent: prop + '.webstore'
    }
  },
  {
    path: '/myexchange',
    name: prop + '.myexchange',
    component: MyExchange,
    meta: {
      parent: prop + '.webstore'
    }
  },
  {
    path: '/scoreorderdetails',
    name: prop + '.scoreorderdetails',
    component: ScoreOrderDetails,
    meta: {
      parent: prop + '.webstore'
    }
  },
  {
    path: '/ExchangeResult',
    name: prop + '.ExchangeResult',
    component: ExchangeResult,
    meta: {
      parent: prop + '.webstore'
    }
  },

  /* Senator */
  {
    path: '/senator',
    name: prop + '.senator',
    meta: {
      auth: true,
      name: 'Senator'
      // img: require('../assets/images/pageBgImg/senatorBg.png')
      // img: require('../assets/images/public/big_banner.jpg')
    },
    component: Senator
  },

  /* MyOtherOrder  */
  {
    path: '/myotherorder',
    name: prop + '.myotherorder',
    component: MyOtherOrder,
    meta: {
      parent: prop + '.myotherorder'
    }
  },
  {
    path: '/orderDet',
    name: prop + '.eventorderdet',
    component: eventOrderDet,
    meta: {
      parent: prop + '.eventorderdet'
    }
  },
  {
    path: '/mobileUserList',
    name: prop + '.mobileUserList',
    component: mobileUserList,
    meta: {
      parent: prop + '.mobileUserList'
    }
  },
  {
    path: '/mobileHistoryList',
    name: prop + '.mobileHistoryList',
    component: mobileHistoryList,
    meta: {
      parent: prop + '.mobileHistoryList'
    }
  },
  {
    path: '/privacyandpolicy',
    name: prop + '.privacyandpolicy',
    component: PrivacyAndPolicy,
    meta: {
      parent: prop + '.privacyandpolicy'
    }
  }
]

/**
 * Router
 * */
const routes = [
  // 全页面
  {
    path: '/auth',
    name: 'auth1',
    component: AuthLayout,
    children: authChildRoutes('auth1')
  },

  {
    path: '/authorize',
    name: 'authorize',
    component: Authorize
  },

  // 后台页面
  {
    path: '/judgeScore',
    name: 'judgeScore',
    component: JudgeScore
  },

  /* Newsfeed */
  {
    path: '/',
    name: 'social',
    component: Layout,
    children: pageRoute('social')
  },
  {
    path: '/privacyAndPolicyAndUser',
    component: PrivacyAndPolicy
  },
  {
    path: '/video',
    component: Video
  },
  {
    path: '/video-chat',
    component: VideoChat
  },
  {
    path: '/error',
    component: Error
  },
  {
    path: '*',
    redirect: '/error'
  }
]

Vue.use(VueRouter)

const router = new VueRouter({
  // mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
const whiteList = [
  '/auth/authentication',
  '/auth/sign-in1',
  '/auth/delete-account',
  '/auth/password-reset-before',
  '/auth/password-reset',
  '/auth/sign-up1',
  '/auth/recovery',
  '/auth/locked',
  '/auth/account-recovery-before',
  '/auth/account-recovery-after',
  '/auth/RecoverPayWord',
  '/auth/2fa-reset',
  '/auth/reset-payword',
  '/privacyAndPolicyAndUser',
  '/judgeScore'
] // 白名单

router.beforeEach((to, from, next) => {
  let token = getlocalStorage('token')
  if (token) {
    if (to.path === '/auth/sign-in1') {
      next({ path: '/' })
    } else {
      next()
    }
  } else {
    if (whiteList.includes(to.path)) {
      next()
    } else {
      let once = localStorage.getItem('once')
      if(once != 1){
        window.localStorage.setItem('fromRouter', to.fullPath)
      }else{
        localStorage.removeItem('once')
      }
      next('/auth/sign-in1')
    }
  }
})

router.onError(error => {
  const pattern = /Loading chunk (\w)+ failed/g
  const isError = error.message.match(pattern)
  const targetPath = router.history.pending.fullPath
  if (isError) {
    router.replace(targetPath)
  }
})

export default router
