import Vue from 'vue'
import moment from 'moment'

Vue.filter('reverse', function (value) {
  // slice to make a copy of array, then reverse the copy
  return value.slice().reverse()
})

Vue.filter('formatDate', function (value) {
  if (value) {
    // console.log(1111, value);
    return moment(String(value)).format()
  }
})

// 过滤男女  0 = 女  1 男
Vue.filter('filterGender', function (val) {
  let sex = val == 1 ? 'Male' : val == 0 ? 'Female' : 'Unisex'
  return sex
})

// 过滤字段是否为空
Vue.filter('filterUnknown', function (val) {
  var result = "";
  if (val == undefined || val.length == 0) {
    result = 'Unknown'
  } else {
    result = val
  }
  return result
})

// 如果
Vue.filter('filterNull', function (val) {
  return val ? val : '-'
})

// 过滤国家地区名字
Vue.filter('filterAddressName', function (val) {
  return val['en_name']
})

Vue.filter('genderFilter', (val) => {
  return val ? '男' : '女'
})

Vue.filter('filterPhonen', (val) => {
  let string = ""
  if (val) {
    string = val.trim()
    return string ? `+${val}` : '--'
  }
})

Vue.filter('formatDateSub', function (currentDate) {
  if (currentDate) {
    let year = moment(currentDate).get('year')
    let month = moment(currentDate).get('month')
    let day = moment(currentDate).get('date')
    let engMonth
    switch (month) {
      case 0: { engMonth = 'Jan'; break }
      case 1: { engMonth = 'Feb'; break }
      case 2: { engMonth = 'Mar'; break }
      case 3: { engMonth = 'Apr'; break }
      case 4: { engMonth = 'May'; break }
      case 5: { engMonth = 'Jun'; break }
      case 6: { engMonth = 'Jul'; break }
      case 7: { engMonth = 'Aug'; break }
      case 8: { engMonth = 'Sep'; break }
      case 9: { engMonth = 'Oct'; break }
      case 10: { engMonth = 'Nov'; break }
      case 11: { engMonth = 'Dec'; break }
    };
    return `${day} ${engMonth} ${year}`
  }
})